import React, { useMemo, useState } from 'react';
import { SelaaCards, SelaaTable, useModal } from '@todcode/components';
import { Box, Container } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import ButtonCreateElement from '@todcode/components/src/component/ButtonCreateElement';
import { toast } from 'react-toastify';

import ModalCopyLink from '@todcode/components/src/component/ModalCopyLink';
import {
  useCreateLenderMutation,
  useDeleteLenderMutation,
  useGetLendersQuery,
  useUpdateLenderMutation,
  useResetPasswordMutation,
} from '../../features/lenders/lendersApiSlice';
import { lenderInitialValues, lenderModalParams, lendersParams } from './index.config';
import { useGetWalletsQuery } from '../../features/wallets/walletsApiSlice';

const Lenders = () => {
  // RTK Query
  const { data: wallets = [] } = useGetWalletsQuery();
  const { data: lendersRaw = [] } = useGetLendersQuery();
  const [deleteLender] = useDeleteLenderMutation();
  const [createLender, { data }] = useCreateLenderMutation();
  const [resetPassword] = useResetPasswordMutation();
  const [updateLender] = useUpdateLenderMutation();
  const [open, setOpen] = useState(false);
  const [openResetPassLink, setOpenResetPassLink] = useState(false);
  const [resetPasswordData, setResetPasswordData] = useState(null);
  // Hook
  const { t } = useTranslation();
  const lenders = useMemo(() => {
    return lendersRaw.map((lender) => {
      const walletName = wallets.find((wallet) => wallet.id === lender.wallet)?.name;

      const parentLender = lendersRaw.find((item) => item.id === lender.parentAccount);
      return { ...lender, walletName, parentLender: parentLender && ` ${parentLender.name}(${parentLender.id})` };
    });
  }, [lendersRaw, wallets]);

  // Callback
  const handleDelete = async (id) => {
    await deleteLender(id);
  };

  const handleCreate = async (values) => {
    const resData = await createLender(values);
    console.log('-> resData', resData);
    if (!resData.error) {
      await setOpen(true);
    }
  };

  const handleUpdate = async (values) => {
    await updateLender(values);
  };

  const handleResetPassword = async (values) => {
    const resData = await resetPassword(values);
    console.log('-> resData', resData);
    if (!resData.error) {
      setResetPasswordData(resData.data);
      await setOpenResetPassLink(true);
    }
  };

  const handleSubmit = (action, values) => {
    switch (action) {
      case 'create':
        if (values.name && values.username && values.wallet) {
          handleCreate(values);
        } else if (!values.name) {
          handleLenderModal(values, 'create');
          toast.error('Please enter Name!');
        } else if (!values.username) {
          handleLenderModal(values, 'create');
          toast.error('Please enter Username!');
        } else if (!values.wallet) {
          handleLenderModal(values, 'create');
          toast.error('Please select Wallet!');
        }
        break;
      case 'update':
        if (values.name && values.username && values.wallet) {
          handleUpdate(values);
        } else if (!values.name) {
          handleLenderUpdate(values, 'update');
          toast.error('Please enter Name!');
        } else if (!values.username) {
          handleLenderUpdate(values, 'update');
          toast.error('Please enter Username!');
        } else if (!values.wallet) {
          handleLenderUpdate(values, 'update');
          toast.error('Please select Wallet!');
        }
        break;
      case 'delete':
        handleDelete(values);
        break;
      case 'resetPassword':
        handleResetPassword(values);
        break;
      default:
        break;
    }
  };

  // Hook
  const { modal, show } = useModal(handleSubmit);

  // Callback
  const handleLenderModal = () => {
    show({
      title: t('modal.title.createL'),
      action: 'create',
      config: lenderModalParams,
      initialValues: lenderInitialValues,
      submitButton: t('button.submit'),
      options: { wallets },
    });
  };
  const handleLenderUpdate = (dates) => {
    show({
      title: t('modal.title.updateL'),
      action: 'update',
      config: lenderModalParams,
      initialValues: { ...lenderInitialValues, ...dates },
      submitButton: t('button.update'),
      options: { wallets },
    });
  };
  const handleApprove = (dates) => {
    show({
      title: t('modal.title.deleteL'),
      action: 'delete',
      config: [],
      initialValues: dates,
      submitButton: t('button.delete'),
    });
  };
  const handleLenderResetPassword = (dates) => {
    show({
      title: 'Reset Password',
      action: 'resetPassword',
      config: [],
      initialValues: dates,
      submitButton: 'Reset Password',
    });
  };

  const handleButtonClick = (action, lender) => {
    switch (action) {
      case 'delete':
        return handleApprove(lender.id);
      case 'update':
        return handleLenderUpdate(lender);
      case 'resetPassword':
        return handleLenderResetPassword(lender);
      default:
        break;
    }
  };
  const handleClose = () => { setOpen(false); setOpenResetPassLink(false); };
  return (
    <Container>
      {modal}
      <ButtonCreateElement icon={<AddIcon />} onClick={() => handleLenderModal()} t={t('button.createLenders')} />
      <ModalCopyLink open={open} data={data} handleClose={handleClose} />
      <ModalCopyLink open={openResetPassLink} data={resetPasswordData} handleClose={handleClose} />
      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        <SelaaTable
          title={t('titleTable.lenders')}
          data={lenders}
          onButtonClick={handleButtonClick}
          params={lendersParams}
        />
      </Box>
      <Box xs={12} sx={{ display: { xs: 'block', sm: 'none' } }}>
        <SelaaCards
          data={lenders}
          onButtonClick={handleButtonClick}
          params={lendersParams}
          visibleFields={4}
        />
      </Box>
    </Container>
  );
};

export default Lenders;
