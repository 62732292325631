import { apiSlice } from '../../store/api/apiSlice';

export const credentialsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCredentials: builder.query({
      query: () => '/credentials',
      providesTags: [''],
    }),
    createCredentials: builder.mutation({
      query: (credential) => ({
        url: 'credentials',
        method: 'POST',
        body: credential,
      }),
      invalidatesTags: [''],
    }),
    deleteCredential: builder.mutation({
      query: (id) => ({
        url: `credentials/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [''],
    }),
  }),
});

export const {
  useCreateCredentialsMutation,
  useGetCredentialsQuery,
  useDeleteCredentialMutation,
} = credentialsApiSlice;
