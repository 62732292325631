import React from 'react';
import { Login } from '@todcode/components';
import { useDispatch } from 'react-redux';
import { useLoginMutation } from '../../features/auth/authApiSlice';
import { setCredentials } from '../../features/auth/authSlice';

const AdminLogin = () => {
  // Hook
  const dispatch = useDispatch();
  // RTK Query
  const [login] = useLoginMutation();
  // Callback
  const handleLogin = async (values) => {
    try {
      const userData = await login(values).unwrap();

      dispatch(setCredentials({ ...userData }));
    } catch (err) {
      if (!err?.originalStatus) {
        console.log('No Server Response');
      } else if (err.originalStatus === 400) {
        console.log('Missing Username or Password');
      } else if (err.originalStatus === 401) {
        console.log('Unauthorized');
      } else {
        console.log('Index Failed');
      }
    }
  };

  return <Login onLogin={handleLogin} />;
};

export default AdminLogin;
