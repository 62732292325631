import { Box, Link as MUILink } from '@mui/material';
import { SvgDownload } from '@todcode/components/src/component/Svg';
import Label from '@todcode/components/src/component/Label';
import { TransferButton } from '@todcode/components/src/component/TransferButton';
import { RedeemButton } from '@todcode/components/src/component/RedeemButton';
import { Trans } from 'react-i18next';
import { capitalize, formatDateTime } from '@todcode/components';

export const transactionsParams = [
  {
    param: 'id',
    label: <Trans i18nKey="transactionTableHeads.id" />,
    align: 'right',
  },
  {
    param: 'ownershipId',
    label: <Trans i18nKey="transactionTableHeads.vId" />,
  },
  {
    param: 'lenderInternalId',
    label: <Trans i18nKey="transactionTableHeads.eId" />,
  },
  {
    param: 'dateTime',
    label: <Trans i18nKey="transactionTableHeads.date" />,
    align: 'center',
    transform: formatDateTime,
  },
  {
    param: 'amount',
    label: <Trans i18nKey="transactionTableHeads.amount" />,
  },
  {
    param: 'lenderName',
    label: <Trans i18nKey="transactionTableHeads.lender" />,
  },
  {
    param: 'type',
    label: <Trans i18nKey="transactionTableHeads.type" />,
    transform: capitalize,
  },
  {
    param: 'ownershipFileUrl',
    label: <Trans i18nKey="transactionTableHeads.pdf" />,
    transform: (value) => {
      if (!value) {
        return '-';
      }

      return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <MUILink download sx={{ ml: '5px' }} onClick={() => { window.open(value); }}>
            <SvgDownload />
          </MUILink>
        </Box>
      );
    },
  },
  {
    param: 'active',
    label: <Trans i18nKey="transactionTableHeads.status" />,
    transform: (value, transaction) => {
      if (transaction.rejects) {
        return <Label value="Rejected" color="red" />;
      }

      if (value && transaction.pending) {
        return <Label value="Pending" color="yellow" />;
      }

      if (value) {
        return <Label value="Active" color="green" />;
      }

      return <Label value="Closed" color="blue" />;
    },
  },
  {
    label: <Trans i18nKey="transactionTableHeads.actions" />,
    align: 'center',
    buttons: [
      {
        Button: TransferButton,
        action: 'transfer',
        isActive: (item) => item.active && item.type === 'buy',
      },
      {
        Button: RedeemButton,
        action: 'redeem',
        isActive: (item) => item.active && item.redeemAllowed,
      },
    ],
  },
];

export const buyModalParams = [
  {
    param: 'amount',
    label: <Trans i18nKey="modal.fieldTransactionCreate.amount" />,
    type: 'input',
  },
  {
    param: 'id',
    label: <Trans i18nKey="modal.fieldTransactionCreate.id" />,
    type: 'input',
  },
  {
    param: 'lenderId',
    label: <Trans i18nKey="modal.fieldTransactionCreate.lender" />,
    selectKey: 'id',
    selectLabel: 'name',
    selectOptionsParam: 'lenders',
    type: 'select',
    defaultValue: true,
  },
];

export const buyInitialValues = {
  amount: '',
  id: '',
  lenderId: false,
};

export const transferModalParams = [
  {
    param: 'name',
    label: <Trans i18nKey="modal.fieldsTransfer.name" />,
    type: 'input',
  },
  {
    param: 'civilId',
    label: <Trans i18nKey="modal.fieldsTransfer.civilId" />,
    type: 'input',
  },
  {
    param: 'phone',
    label: <Trans i18nKey="modal.fieldsTransfer.phone" />,
    type: 'input',
  },
  {
    param: 'birthday',
    label: <Trans i18nKey="modal.fieldsTransfer.birthday" />,
    type: 'input',
    mask: '9999-99-99',
    placeholder: 'YYYY-MM-DD',
  },
];

export const transferInitialValues = {
  name: '',
  // civilId: null,
  // phone: null,
  // birthday: null,
};
