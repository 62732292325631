import { DeleteButton, EditButton } from '@todcode/components';
import { Trans } from 'react-i18next';
import { ResetButton } from '@todcode/components/src/component/ResetButton';

export const lendersParams = [
  {
    param: 'id',
    label: <Trans i18nKey="lendersTableHeads.id" />,
    align: 'right',
  },
  {
    param: 'name',
    label: <Trans i18nKey="lendersTableHeads.name" />,
  },
  {
    param: 'note',
    label: <Trans i18nKey="lendersTableHeads.note" />,
  },
  {
    param: 'walletName',
    label: <Trans i18nKey="lendersTableHeads.wallet" />,
  },
  {
    param: 'username',
    label: <Trans i18nKey="lendersTableHeads.username" />,
  },
  // {
  //   param: 'password',
  //   label: <Trans i18nKey="lendersTableHeads.password" />,
  // },
  {
    param: 'parentLender',
    label: <Trans i18nKey="lendersTableHeads.nameId" />,
  },
  {
    label: <Trans i18nKey="lendersTableHeads.actions" />,
    buttons: [
      {
        Button: DeleteButton,
        action: 'delete',
      },
      {
        Button: EditButton,
        action: 'update',
      },
      {
        Button: ResetButton,
        action: 'resetPassword',
      },
    ],
  },

];

export const lenderModalParams = [
  {
    param: 'name',
    label: <Trans i18nKey="modal.fieldsLender.name" />,
    type: 'input',
  },

  {
    param: 'username',
    label: <Trans i18nKey="modal.fieldsLender.username" />,
    type: 'input',
  },
  // {
  //   param: 'password',
  //   label: <Trans i18nKey="modal.fieldsLender.password" />,
  //   type: 'input',
  // },
  {
    param: 'wallet',
    label: <Trans i18nKey="modal.fieldsLender.wallet" />,
    selectKey: 'id',
    selectLabel: 'name',
    selectOptionsParam: 'wallets',
    type: 'select',
    defaultValue: true,
  },
  {
    param: 'note',
    label: <Trans i18nKey="modal.fieldsLender.note" />,
    type: 'input',
  },
];

export const lenderInitialValues = {
  name: '',
  username: '',
  // password: '',
  wallet: false,
  note: '',
};
