import React, { useMemo } from 'react';
import {
  Container, Box,
} from '@mui/material';
import {
  formatDateTime, SelaaCards, SelaaTable, useModal,
} from '@todcode/components';
import { useTranslation } from 'react-i18next';
import { useApproveMutation, useRejectMutation } from '@workspace/lenders/src/features/approve/approveApiSlice';
import { historyInfoModalParams } from '@todcode/components/src/component/ModalConfig';
import { intentParams } from './index.config';
import { useGetIntentsQuery } from '../../features/intent/intentApiSlice';
import { useGetLendersQuery } from '../../features/lenders/lendersApiSlice';

const Intent = () => {
  // RTK Query
  const { data: intentsRaw = [] } = useGetIntentsQuery();
  const { data: lenders = [] } = useGetLendersQuery();
  const [approve] = useApproveMutation();
  const [reject] = useRejectMutation();
  // Hook
  const intents = useMemo(() => {
    return intentsRaw.map((intent) => {
      const lenderName = lenders.find((lender) => intent.lenderId === lender.id)?.name;

      return { ...intent, lenderName };
    });
  }, [intentsRaw, lenders]);
  // Callback
  const handleApprove = async (id) => {
    await approve(id);
  };

  const handleReject = async (id) => {
    await reject(id);
  };

  const handleSubmit = (action, values) => {
    switch (action) {
      case 'approve':
        handleApprove(values.id);
        break;
      case 'reject':
        handleReject(values.id);
        break;
      default:
        break;
    }
  };

  // Hook
  const { modal, show } = useModal(handleSubmit);
  const { t } = useTranslation();

  // Callback
  const handleApproveInit = (transaction) => {
    const dateTime = formatDateTime(transaction.dateTime);
    const transactionData = { ...transaction, dateTime };
    show({
      title: t('modal.title.confirm'), action: 'approve', config: historyInfoModalParams, initialValues: transactionData, submitButton: t('button.approve'),
    });
  };

  const handleRejectInit = (transaction) => {
    const dateTime = formatDateTime(transaction.dateTime);
    const transactionData = { ...transaction, dateTime };
    show({
      title: t('modal.title.reject'), action: 'reject', config: historyInfoModalParams, initialValues: transactionData, submitButton: t('button.reject'),
    });
  };

  const handleButtonClick = (action, data) => {
    switch (action) {
      case 'approve':
        return handleApproveInit(data);
      case 'reject':
        return handleRejectInit(data);
      default:
        break;
    }
  };
  return (
    <Container>
      {modal}
      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        <SelaaTable
          title={t('titleTable.intent')}
          data={intents}
          params={intentParams}
          onButtonClick={handleButtonClick}
        />
      </Box>
      <Box xs={12} sx={{ display: { xs: 'block', sm: 'none' } }}>
        <SelaaCards
          data={intents}
          params={intentParams}
          visibleFields={4}
          onButtonClick={handleButtonClick}
        />
      </Box>
    </Container>
  );
};

export default Intent;
