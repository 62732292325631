import {
  SvgCredentials, SvgHistory, SvgIntent, SvgOrder, SvgUsers, SvgWallet, SvgReports,
} from '@todcode/components/src/component/Svg';
import { Trans } from 'react-i18next';

const path = [
  {
    link: '/wallets',
    img: SvgWallet,
    text: <Trans i18nKey="path.wallets" />,
  },
  {
    link: 'lenders',
    img: SvgUsers,
    text: <Trans i18nKey="path.lenders" />,
  },
  {
    link: 'transactions',
    img: SvgOrder,
    text: <Trans i18nKey="path.transactions" />,
  },
  {
    link: 'credentials',
    img: SvgCredentials,
    text: <Trans i18nKey="path.credentials" />,
  },
  {
    link: 'requests',
    img: SvgIntent,
    text: <Trans i18nKey="path.intents" />,
  },
  {
    link: 'history',
    img: SvgHistory,
    text: <Trans i18nKey="path.history" />,
  },
  {
    link: 'reports',
    img: SvgReports,
    text: <Trans i18nKey="path.reports" />,
  },
];

export default path;
