import React, { useEffect } from 'react';
import {
  Container, Box,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import { SelaaTable, SelaaCards, useModal } from '@todcode/components';
import { useTranslation } from 'react-i18next';
import ButtonCreateElement from '@todcode/components/src/component/ButtonCreateElement';
import {
  useCreateWalletMutation,
  useTopUpWalletMutation,
  useDeleteWalletMutation,
  useGetWalletsQuery,
  useUpdateWalletMutation,
} from '../../features/wallets/walletsApiSlice';
import {
  walletInitialValues,
  walletModalParams,
  walletParams,
  walletTopUpModalParams,
} from './index.config';

const Wallets = () => {
  // RTK Query
  const { data: wallets = [], error } = useGetWalletsQuery();
  const [deleteContact] = useDeleteWalletMutation();
  const [createWallet] = useCreateWalletMutation();
  const [topUpWallet] = useTopUpWalletMutation();
  const [updateWallet] = useUpdateWalletMutation();
  // Hook
  const { t } = useTranslation();

  useEffect(() => {
    if (error) toast.error(t('error'));
  });

  // Callback
  const handleCreate = async (values) => {
    await createWallet(values);
  };

  const handleTopUp = async (values) => {
    await topUpWallet(values);
  };

  const handleDelete = async (id) => {
    await deleteContact(id);
  };

  const handleUpdate = async (values) => {
    await updateWallet(values);
  };

  const handleSubmit = (action, values) => {
    switch (action) {
      case 'create':
        if (values.name && values.bank && values.amount) {
          handleCreate(values);
        } else if (!values.name) {
          handleWalletModal(values, 'create');
          toast.error('Please enter Name!');
        } else if (!values.bank) {
          handleWalletModal(values, 'create');
          toast.error('Please enter Bank Details!');
        } else if (!values.amount) {
          handleWalletModal(values, 'create');
          toast.error('Please enter Amount!');
        }
        break;
      case 'update':
        if (values.name && values.bank && values.amount) {
          handleUpdate(values);
        } else if (!values.name) {
          handleWalletModal(values, 'update');
          toast.error('Please enter Name!');
        } else if (!values.bank) {
          handleWalletModal(values, 'update');
          toast.error('Please enter Bank Details!');
        } else if (!values.amount) {
          handleWalletModal(values, 'update');
          toast.error('Please enter Amount!');
        }
        break;
      case 'topUp':
        if (values.topUpAmount) {
          handleTopUp(values);
        } else if (!values.topUpAmount) {
          handleTopUpWalletModal(values, 'topUp');
          toast.error('Please enter Amount!');
        }
        break;
      case 'delete':
        handleDelete(values);
        break;
      default:
        break;
    }
  };

  // Hook
  const { modal, show } = useModal(handleSubmit);

  // Callback
  const handleWalletModal = () => {
    show({
      title: t('modal.title.createW'), action: 'create', config: walletModalParams, initialValues: walletInitialValues, submitButton: t('button.submit'), options: { wallets },
    });
  };
  const handleTopUpWalletModal = (walletItem) => {
    show({
      title: t(`Top Up Wallet ( ${walletItem.name} )`), action: 'topUp', config: walletTopUpModalParams, submitButton: t('button.submit'), initialValues: walletItem,
    });
  };
  const handleWalletUpdate = (dates) => {
    show({
      title: t('modal.title.updateW'), action: 'update', config: walletModalParams, initialValues: { ...walletInitialValues, ...dates }, submitButton: t('button.update'), options: { wallets },
    });
  };
  const handleApprove = (dates) => {
    show({
      title: t('modal.title.deleteW'), action: 'delete', config: [], initialValues: dates, submitButton: t('button.delete'),
    });
  };
  const handleButtonClick = (action, wallet) => {
    switch (action) {
      case 'delete':
        return handleApprove(wallet.id);
      case 'edit':
        return handleWalletUpdate(wallet);
      case 'topup':
        return handleTopUpWalletModal(wallet);
      default:
        break;
    }
  };

  return (
    <Container>
      {modal}
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <ButtonCreateElement icon={<AddIcon />} onClick={() => handleWalletModal()} t={t('button.createWallet')} />
      </div>
      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        <SelaaTable
          title={t('titleTable.wallets')}
          data={wallets}
          onButtonClick={handleButtonClick}
          params={walletParams}
        />
      </Box>
      <Box xs={12} sx={{ display: { xs: 'block', sm: 'none' } }}>
        <SelaaCards
          data={wallets}
          onButtonClick={handleButtonClick}
          params={walletParams}
          visibleFields={4}
        />
      </Box>
    </Container>
  );
};

export default Wallets;
