import { apiSlice } from '../../store/api/apiSlice';

export const transactionsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getIntents: builder.query({
      query: () => '/intents/all',
      providesTags: ['Intents'],
    }),
    getIntentLenders: builder.query({
      query: (id) => ({
        url: `/intents/${id}`,
        method: 'GET',
      }),
      providesTags: ['Intents'],
    }),
    get: builder.query({
      query: (id) => ({
        url: `/intents/single/${id}`,
        method: 'GET',
      }),
      providesTags: ['Intents'],
    }),
  }),
});

export const { useGetIntentsQuery, useLazyGetQuery } = transactionsApiSlice;
