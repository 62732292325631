import { apiSlice } from '../../store/api/apiSlice';

export const buyApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    buy: builder.mutation({
      query: ({ amount, id, lenderId }) => ({
        url: '/operations/buy',
        method: 'POST',
        body: { amount: parseFloat(amount), id, lenderId },
      }),
      invalidatesTags: ['Transactions', 'AvailableAmount'],
    }),
  }),
});

export const {
  useBuyMutation,
} = buyApiSlice;
