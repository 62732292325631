import { apiSlice } from '../../store/api/apiSlice';

export const lendersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLenders: builder.query({
      query: () => '/lenders',
      providesTags: ['Lender'],
    }),
    createLender: builder.mutation({
      query: (lender) => {
        return {
          url: '/lenders',
          method: 'POST',
          body: { ...lender, wallet: parseInt(lender.wallet, 10) || 0 },
        };
      },
      invalidatesTags: ['Lender'],
      transformResponse: (response) => `${response.link}?username=${response.username}`,
    }),
    deleteLender: builder.mutation({
      query: (id) => ({
        url: `lenders/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Lender'],
    }),
    getLender: builder.query({
      query: (id) => ({
        url: `/lenders/${id}`,
        method: 'GET',
      }),
      providesTags: ['Lender'],
    }),
    updateLender: builder.mutation({
      query: (lender) => ({
        url: `/lenders/${lender.id}`,
        method: 'PATCH',
        body: lender,
      }),
      invalidatesTags: ['Lender'],
    }),
    resetPassword: builder.mutation({
      query: (lender) => {
        return {
          url: '/lenders/link',
          method: 'POST',
          body: { lenderId: lender.id },
        };
      },
      invalidatesTags: ['Lender'],
      transformResponse: (response) => `${response.link}?username=${response.username}`,
    }),
    lenderWiseReports: builder.mutation({
      query: () => {
        return {
          url: '/lenders/lenderWiseReports',
          method: 'POST',
          body: {},
        };
      },
    }),
  }),
});

export const {
  useGetLendersQuery,
  useCreateLenderMutation,
  useDeleteLenderMutation,
  useGetLenderQuery,
  useUpdateLenderMutation,
  useResetPasswordMutation,
  useLenderWiseReportsMutation,
} = lendersApiSlice;
