import React, { useMemo, useEffect } from 'react';
import {
  Container, Box,
} from '@mui/material';
import {
  SelaaTable, SelaaCards, useModal, formatDateTime,
} from '@todcode/components';
import { useTranslation } from 'react-i18next';
import {
  intentModal, transactionModal,
} from '@todcode/components/src/component/ModalConfig';
import { historyParams } from './index.config';
import { historyApiSlice } from '../../features/history/historyApiSlice';
import { useGetLendersQuery } from '../../features/lenders/lendersApiSlice';
import { useGetWalletsQuery } from '../../features/wallets/walletsApiSlice';

const History = () => {
  // RTK Query
  const [trigger, { data: historyRaw = [] }] = historyApiSlice.useLazyGetHistoryQuery();
  const { data: lenders = [] } = useGetLendersQuery();
  const { data: wallets = [] } = useGetWalletsQuery();

  // Hook
  const { t } = useTranslation();
  const history = useMemo(() => {
    return historyRaw.map((item) => {
      let lenderName = lenders.find((lender) => item.lenderId === lender.id)?.name;
      if (!lenderName && item.walletName) {
        lenderName = `${item.walletName} (Ad)`;
      }
      return { ...item, lenderName };
    });
  }, [historyRaw, lenders, wallets]);

  useEffect(() => {
    trigger();
  }, []);

  const { modal, show } = useModal();

  // Callback
  const handleMore = async ({ transaction: transactionDate, intent }) => {
    const values = {};
    let params = [];

    if (transactionDate) {
      const dateTime = formatDateTime(transactionDate.dateTime);
      const transaction = { ...transactionDate, dateTime };

      values.transaction = transaction;
      params = [...params, ...transactionModal];
    }

    if (intent) {
      const dateTime = formatDateTime(intent.dateTime);
      const transaction = { ...intent, dateTime };
      values.intent = transaction;
      params = [...params, ...intentModal];
    }

    show({
      title: t('modal.title.details'),
      config: params,
      initialValues: values,
      readOnly: true,
    });
  };

  const handleButtonClick = (action, data) => {
    switch (action) {
      case 'moreInfo':
        return handleMore(data);
      default:
        break;
    }
  };
  return (
    <Container>
      {modal}
      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        <SelaaTable
          t={t}
          title={t('titleTable.history')}
          data={history}
          params={historyParams}
          onButtonClick={handleButtonClick}
        />
      </Box>
      <Box xs={12} sx={{ display: { xs: 'block', sm: 'none' } }}>
        <SelaaCards
          data={history}
          params={historyParams}
          visibleFields={4}
          onButtonClick={handleButtonClick}
        />
      </Box>

    </Container>
  );
};

export default History;
