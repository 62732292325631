import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Backdrop, Box, CircularProgress } from '@mui/material';
import { logOut } from '@workspace/lenders/src/features/auth/authSlice';
import { ToastContainer } from 'react-toastify';
import Main from '@todcode/components/src/layout/Main';
import LayoutLogin from '@todcode/components/src/layout/Login';
import { selectCurrentToken } from './features/auth/authSlice';
import Wallets from './scenes/Wallets';
import Lenders from './scenes/Lenders';
import Transaction from './scenes/Transactions';
import AdminLogin from './scenes/Login';
import './App.css';
import path from './path/index.config';
import Credentials from './scenes/Credentials';
import PDFPage from './scenes/PDF';
import 'react-toastify/dist/ReactToastify.css';
import { apiSlice } from './store/api/apiSlice';
import History from './scenes/History';
import Intent from './scenes/Intent';
import Reports from './scenes/Reports';

const App = () => {
  // Hook
  const dispatch = useDispatch();
  // Selector
  const token = useSelector(selectCurrentToken);
  const isSomeQueryPending = useSelector((state) => Object.values(state.api.queries).some((query) => query.status === 'pending') || Object.values(state.api.mutations).some((query) => query.status === 'pending'));

  // Callback
  const handleLogout = () => {
    dispatch(logOut());
    dispatch(apiSlice.util.resetApiState());
  };

  return (
    <div className="App">
      <ToastContainer />
      <Backdrop open={isSomeQueryPending} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ height: '100%' }}>
        {token ? (
          <Box sx={{ height: '100%' }}>
            <Routes>
              <Route path="/" element={<Main path={path} onLogout={handleLogout} />}>
                {/* Wallets */}
                <Route path="wallets" element={<Wallets />} />
                {/* Lenders */}
                <Route path="lenders" element={<Lenders />} />
                {/* Transactions */}
                <Route path="transactions" element={<Transaction />} />
                {/* Credentials */}
                <Route path="credentials" element={<Credentials />} />
                {/* Intent */}
                <Route path="requests" element={<Intent />} />
                {/* History */}
                <Route path="history" element={<History />} />
                {/* PDF */}
                <Route path="pdf" element={<PDFPage />} />

                <Route path="reports" element={<Reports />} />

                <Route path="*" element={<Navigate to="transactions" />} />
                <Route path="/" element={<Navigate to="transactions" />} />
              </Route>
            </Routes>
          </Box>
        ) : (
          <Routes>
            <Route path="/" element={<LayoutLogin />}>
              <Route path="/login" element={<AdminLogin />} />
              <Route path="*" element={<Navigate to="/login" />} />
              <Route path="/" element={<Navigate to="/login" />} />
            </Route>
          </Routes>
        )}
      </Box>
    </div>
  );
};

export default App;
