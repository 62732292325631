import DeleteIcon from '@mui/icons-material/Delete';
import { MButton } from '../Fab';

export const DeleteButton = ({ onClick }) => {
  return (
    <MButton onClick={onClick}>
      <DeleteIcon sx={{ color: '#fff' }} />
    </MButton>
  );
};
