import EditIcon from '@mui/icons-material/Edit';
import { MButton } from '../Fab';

export const EditButton = ({ onClick }) => {
  return (
    <MButton onClick={onClick}>
      <EditIcon sx={{ color: '#fff' }} />
    </MButton>
  );
};
