import React, { useEffect, useMemo } from 'react';
import {
  Container, Box,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import { SelaaTable, SelaaCards, useModal } from '@todcode/components';
import { useTranslation } from 'react-i18next';
import ButtonCreateElement from '@todcode/components/src/component/ButtonCreateElement';
import { credentialsInitialValues, credentialsModalParams, credentialsParams } from './index.config';
import {
  useCreateCredentialsMutation,
  useDeleteCredentialMutation,
  useGetCredentialsQuery,
} from '../../features/credentials/credentialsApiSlice';
import { useGetLendersQuery } from '../../features/lenders/lendersApiSlice';
import { useGetWalletsQuery } from '../../features/wallets/walletsApiSlice';

const Credentials = () => {
  // RTK Query
  const { data: credentialsRaw = [], error } = useGetCredentialsQuery();
  const [deleteContact] = useDeleteCredentialMutation();
  const [createWallet] = useCreateCredentialsMutation();
  const { data: lenders = [] } = useGetLendersQuery();
  const { data: wallets = [] } = useGetWalletsQuery();

  // Hook
  const { t } = useTranslation();
  const credentials = useMemo(() => {
    return credentialsRaw.map((credential) => {
      const walletName = wallets.find((wallet) => wallet.id === credential.wallet)?.name;
      const lenderName = lenders.find((lender) => lender.id === credential.lender)?.name;

      return { ...credential, lenderName, walletName };
    });
  }, [credentialsRaw, lenders, wallets]);

  useEffect(() => {
    if (error) toast.error(t('error'));
  });
  // Callback
  const handleDelete = async ({ id }) => {
    await deleteContact(id);
  };

  const handleCreate = async (values) => {
    await createWallet(values);
  };

  const handleSubmit = (action, values) => {
    switch (action) {
      case 'create':
        if (values.name && values.lenderId && values.wallet) {
          handleCreate(values);
        } else if (!values.name) {
          handleCredentialsModal(values, 'create');
          toast.error('Please enter Name!');
        } else if (!values.lenderId) {
          handleCredentialsModal(values, 'create');
          toast.error('Please enter Lender!');
        } else if (!values.wallet) {
          handleCredentialsModal(values, 'create');
          toast.error('Please select Wallet!');
        }
        break;
      case 'delete':
        handleDelete(values);
        break;
      default:
        break;
    }
  };

  // Hook
  const { modal, show } = useModal(handleSubmit);

  // Callback
  const handleCredentialsModal = () => {
    show({
      title: t('modal.title.createC'), action: 'create', config: credentialsModalParams, initialValues: credentialsInitialValues, submitButton: t('button.submit'), options: { wallets, lenders },
    });
  };
  const handleApprove = (dates) => {
    show({
      title: t('modal.title.deleteC'), action: 'delete', config: [], initialValues: { id: dates.id }, submitButton: t('button.delete'),
    });
  };

  const handleButtonClick = (action, wallet) => {
    switch (action) {
      case 'delete':
        return handleApprove(wallet);
      default:
        break;
    }
  };
  return (
    <Container>
      {modal}
      <ButtonCreateElement icon={<AddIcon />} onClick={() => handleCredentialsModal()} t={t('button.createCredentials')} />
      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        <SelaaTable
          title={t('titleTable.wallets')}
          data={credentials}
          onButtonClick={handleButtonClick}
          params={credentialsParams}
        />
      </Box>
      <Box xs={12} sx={{ display: { xs: 'block', sm: 'none' } }}>
        <SelaaCards
          data={credentials}
          onButtonClick={handleButtonClick}
          params={credentialsParams}
          visibleFields={4}
        />
      </Box>

    </Container>
  );
};

export default Credentials;
