import { apiSlice } from '../../store/api/apiSlice';

export const walletsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getWallets: builder.query({
      query: () => '/wallets',
      providesTags: ['Wallet'],
    }),
    createWallet: builder.mutation({
      query: (wallet) => ({
        url: '/wallets',
        method: 'POST',
        body: { ...wallet, amount: parseFloat(wallet.amount) },
      }),
      invalidatesTags: ['Wallet'],
    }),
    topUpWallet: builder.mutation({
      query: (wallet) => ({
        url: `/wallets/topupWalletAmount/${wallet.id}`,
        method: 'POST',
        body: { ...wallet, amount: parseFloat(wallet.topUpAmount), walletName: wallet.name },
      }),
      invalidatesTags: ['Wallet'],
    }),
    updateWallet: builder.mutation({
      query: (wallet) => {
        const body = {
          ...wallet,
        };

        if (body.amount) {
          body.amount = parseFloat(body.amount);
        }

        return {
          url: `/wallets/${wallet.id}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: ['Wallet'],
    }),
    deleteWallet: builder.mutation({
      query: (id) => ({
        url: `wallets/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Wallet'],
    }),
    getWallet: builder.query({
      query: (id) => ({
        url: `/wallets/${id}`,
        method: 'GET',
      }),
      providesTags: ['Wallet'],
    }),
  }),
});

export const {
  useGetWalletsQuery,
  useCreateWalletMutation,
  useTopUpWalletMutation,
  useUpdateWalletMutation,
  useDeleteWalletMutation,
  useGetWalletQuery,
} = walletsApiSlice;
