import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { MButton } from '../Fab';

export const TopUpButton = ({ onClick }) => {
  return (
    <MButton onClick={onClick}>
      <AttachMoneyIcon sx={{ color: '#fff' }} />
    </MButton>
  );
};
