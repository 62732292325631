import { Box,} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React from 'react';

const DatePickerElement = ({ onClick, t, maxDate, minDate }) => {
  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      mb: '15px',
    }}
    >
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
       <DatePicker label={t} onChange={(value) => { onClick(value)}} maxDate={maxDate} minDate={minDate} />
      </DemoContainer>
    </LocalizationProvider>
    </Box>
  );
};

export default DatePickerElement;
