import { DeleteButton } from '@todcode/components';
import { Trans } from 'react-i18next';

export const credentialsParams = [
  {
    param: 'name',
    label: <Trans i18nKey="credentialsTableHeads.name" />,
  },
  {
    param: 'lenderName',
    label: <Trans i18nKey="credentialsTableHeads.lender" />,
  },
  {
    param: 'walletName',
    label: <Trans i18nKey="credentialsTableHeads.wallet" />,
  },
  {
    param: 'id',
    label: <Trans i18nKey="credentialsTableHeads.id" />,
  },
  {
    param: 'secret',
    label: <Trans i18nKey="credentialsTableHeads.secret" />,
  },
  {
    label: <Trans i18nKey="credentialsTableHeads.actions" />,
    buttons: [
      {
        Button: DeleteButton,
        action: 'delete',
      },
    ],
  },
];

export const credentialsModalParams = [
  {
    param: 'name',
    label: <Trans i18nKey="modal.fieldsCredentials.name" />,
    type: 'input',
  },
  {
    param: 'lenderId',
    label: <Trans i18nKey="modal.fieldsCredentials.lender" />,
    selectKey: 'id',
    selectLabel: 'name',
    selectOptionsParam: 'lenders',
    type: 'select',
  },
  {
    param: 'wallet',
    label: <Trans i18nKey="modal.fieldsCredentials.wallet" />,
    selectKey: 'id',
    selectLabel: 'name',
    selectOptionsParam: 'wallets',
    type: 'select',
  },
];

export const credentialsInitialValues = {
  name: '',
  lenderId: '',
  wallet: '',
};
