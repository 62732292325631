import { DeleteButton, EditButton } from '@todcode/components';
import { SvgApprove } from '@todcode/components/src/component/Svg';
import { TopUpButton } from '@todcode/components/src/component/TopUpButton';
import { Trans } from 'react-i18next';

export const walletParams = [
  {
    param: 'id',
    label: <Trans i18nKey="walletTableHeads.id" />,
    align: 'right',
  },
  {
    param: 'name',
    label: <Trans i18nKey="walletTableHeads.name" />,
  },
  {
    param: 'bank',
    label: <Trans i18nKey="walletTableHeads.bank" />,
  },
  {
    param: 'note',
    label: <Trans i18nKey="walletTableHeads.note" />,
  },
  {
    param: 'amount',
    label: <Trans i18nKey="walletTableHeads.amount" />,
  },
  {
    param: 'locked',
    label: <Trans i18nKey="walletTableHeads.locked" />,
  },
  {
    param: 'reducing',
    label: <Trans i18nKey="walletTableHeads.reducing" />,
    transform: (value) => value && <SvgApprove color="#32936F" />,
  },
  {
    param: 'default',
    label: <Trans i18nKey="walletTableHeads.default" />,
    transform: (value) => value && <SvgApprove color="#32936F" />,
  },
  {
    label: <Trans i18nKey="walletTableHeads.actions" />,
    buttons: [
      {
        Button: TopUpButton,
        action: 'topup',

      },
      {
        Button: EditButton,
        action: 'edit',

      },
      {
        Button: DeleteButton,
        action: 'delete',
      },
    ],
  },
];

export const walletModalParams = [
  {
    param: 'name',
    label: <Trans i18nKey="modal.fieldsWallet.name" />,
    type: 'input',
  },
  {
    param: 'bank',
    label: <Trans i18nKey="modal.fieldsWallet.bank" />,
    type: 'input',
  },

  {
    param: 'amount',
    label: <Trans i18nKey="modal.fieldsWallet.amount" />,
    type: 'input',
  },
  {
    param: 'note',
    label: <Trans i18nKey="modal.fieldsWallet.note" />,
    type: 'input',
  },
  {
    param: 'reducing',
    label: <Trans i18nKey="modal.fieldsWallet.reducing" />,
    type: 'checkbox',
  },
  {
    param: 'default',
    label: <Trans i18nKey="modal.fieldsWallet.default" />,
    type: 'checkbox',
  },

];

export const walletTopUpModalParams = [
  {
    param: 'topUpAmount',
    label: <Trans i18nKey="modal.fieldsWallet.amount" />,
    type: 'input',
  },

];

export const walletInitialValues = {
  name: '',
  bank: '',

  amount: '',
  note: '',
  reducing: false,
  default: false,

};
