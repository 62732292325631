import React, { useState } from 'react';
import {
  Container,
} from '@mui/material';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { toast } from 'react-toastify';
import xlsx from 'json-as-xlsx';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useTranslation } from 'react-i18next';
import GenerateReportButton from '@todcode/components/src/component/GenerateReportButton';
import DatePickerElement from '@todcode/components/src/component/DatePickerElement';
import { useLenderWiseReportsMutation } from '../../features/lenders/lendersApiSlice';

const adapter = new AdapterDayjs();

const Reports = () => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  // RTK Query
  const [lenderWiseReports] = useLenderWiseReportsMutation();
  // Hook
  const { t } = useTranslation();

  const generateReport = async () => {
    if (fromDate === null && toDate === null) {
      toast.error(t('Please select dates'), { autoClose: 1000 });
      return;
    }
    if (adapter.date(toDate).isBefore(adapter.date(fromDate))) {
      toast.error(t('To date cannot be less than from date'), { autoClose: 1000 });
      return;
    }
    const reportsJson = await lenderWiseReports();
    const newArry = [];
    reportsJson.data.forEach((element) => {
      if (element.Transaction_Date
          && adapter.date(element.Transaction_Date).isAfter(adapter.date(fromDate))
          && adapter.date(element.Transaction_Date).isBefore(adapter.date(toDate).add(1, 'day'))) {
        newArry.push(element);
      }
    });
    if (newArry.length < 1) {
      toast.error(t('No transactions within selected dates'), { autoClose: 2000 });
      return;
    }
    const settings = { fileName: `LenderWiseReport_${new Date().getDate()}_${(new Date().getMonth()) + 1}_${new Date().getFullYear()}_${new Date().getHours()}_${new Date().getMinutes()}_${new Date().getSeconds()}` };
    const data = [
      {
        sheet: 'Adults',
        columns: [
          { label: 'Lender Name', value: 'Lender_Name' },
          { label: 'Lender Id', value: 'Lender_Id' },
          { label: 'Transaction Id', value: 'Transaction_Id' },
          { label: 'Transaction Type', value: 'Transaction_Type' },
          { label: 'Transaction Amount', value: 'Transaction_Amount' },
          { label: 'Transaction InternalId', value: 'Transaction_InternalId' },
          { label: 'Transaction OwnershipId', value: 'Transaction_OwnershipId' },
          { label: 'Transaction Link', value: 'Transaction_Link' },
          { label: 'Transaction Date', value: 'Transaction_Date' },
        ],
        content: newArry,
      },
    ];
    xlsx(data, settings, () => { toast.success(t('File Downloaded'), { autoClose: 1000 }); });
  };

  return (
    <Container>
      <div style={{ display: 'grid', marginBottom: 10 }}>
        <div>
          <DatePickerElement onClick={(val) => { setFromDate(adapter.date(val)); }} t={t('From Date')} maxDate={adapter.date(new Date())} />
        </div>
        <div>
          <DatePickerElement onClick={(val) => { setToDate(adapter.date(val)); }} t={t('To Date')} maxDate={adapter.date(new Date())} minDate={fromDate !== null ? fromDate : null} />
        </div>
      </div>
      <GenerateReportButton icon={<SummarizeIcon />} onClick={() => { generateReport(); }} t={t('Generate Report')} />
    </Container>
  );
};

export default Reports;
