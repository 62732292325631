import { apiSlice } from '../../store/api/apiSlice';

export const transactionsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTransactions: builder.query({
      query: () => '/transactions',
      providesTags: ['Transactions'],
    }),
    getTransaction: builder.query({
      query: (id) => ({
        url: `/transactions/${id}`,
        method: 'GET',
      }),
      providesTags: ['Transactions'],
    }),
  }),
});

export const {
  useGetTransactionsQuery,
  useLazyGetTransactionQuery,
} = transactionsApiSlice;
