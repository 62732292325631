import { apiSlice } from '../../store/api/apiSlice';

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: 'auth/login',
        method: 'POST',
        body: credentials,
      }),
    }),
    refresh: builder.mutation({
      query: (credentials) => ({
        url: 'auth/refresh',
        method: 'POST',
        body: credentials,
      }),
    }),
    logOut: builder.mutation({
      query: () => ({
        url: '/auth/logout',
        method: 'GET',
      }),
    }),
  }),
});

export const { useLoginMutation, useLogOutQuery } = authApiSlice;
