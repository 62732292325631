import React
, { useMemo, useEffect } from 'react';
import {
  Box,
  Container,
} from '@mui/material';
import { toast } from 'react-toastify';

import {
  formatDateTime, SelaaCards, SelaaTable, useModal,
} from '@todcode/components';
import { useTranslation } from 'react-i18next';
import ButtonCreateElement from '@todcode/components/src/component/ButtonCreateElement';
import { historyInfoModalParams } from '@todcode/components/src/component/ModalConfig';
import {
  buyInitialValues,
  buyModalParams,
  transactionsParams,
  transferInitialValues,
  transferModalParams,
} from './index.config';
import { useGetLendersQuery } from '../../features/lenders/lendersApiSlice';
import { useGetTransactionsQuery } from '../../features/transactions/transactionsApiSlice';
import { useBuyMutation } from '../../features/buy/buyApiSlice';
import { useTransferMutation } from '../../features/transfer/transferApiSlice';
import { useRedeemMutation } from '../../features/redeem/redeemApiSlice';

const Transaction = () => {
  // RTK Query

  const { data: transactionsRaw = [], transactionsError } = useGetTransactionsQuery();
  const { data: lenders = [], lendersError } = useGetLendersQuery();

  const [buy] = useBuyMutation();
  const [transfer] = useTransferMutation();
  const [redeem] = useRedeemMutation();

  // Hook
  const transactions = useMemo(() => {
    return transactionsRaw.map((transaction) => {
      const lenderName = lenders.find((lender) => transaction.lenderId === lender.id)?.name;

      return { ...transaction, lenderName };
    });
  }, [transactionsRaw, lenders]);

  const { t } = useTranslation();

  useEffect(() => {
    if (transactionsError) toast.error(t('error'));
    if (lendersError) toast.error(t('error'));
  });

  // Callback
  const handleBuy = async (values) => {
    await buy(values);
  };

  const handleTransfer = async (values) => {
    await transfer(values);
  };

  const handleRedeem = async (id) => {
    await redeem(id);
  };

  const handleSubmit = (action, values) => {
    switch (action) {
      case 'buy':
        if (values.amount && values.id && values.lenderId) {
          handleBuy(values);
        } else if (!values.amount) {
          handleBuyModal(values, 'create');
          toast.error('Please enter amount!');
        } else if (!values.id) {
          handleBuyModal(values, 'create');
          toast.error('Please enter InternalId!');
        } else if (!values.lenderId) {
          handleBuyModal(values, 'create');
          toast.error('Please select lender!');
        }
        break;
      case 'transfer':
        if (values.name && values.civilId && values.phone) {
          handleTransfer(values);
        } else if (!values.name) {
          handleTransferModal(values, 'create');
          toast.error('Please enter Name!');
        } else if (!values.phone) {
          handleTransferModal(values, 'create');
          toast.error('Please enter Phone!');
        } else if (!values.civilId) {
          handleTransferModal(values, 'create');
          toast.error('Please enter civilId!');
        }
        break;
      case 'redeem':
        handleRedeem(values.ownershipId);
        break;
      default:
        break;
    }
  };
  // Hook
  const { modal, show } = useModal(handleSubmit);

  // Callback
  const handleBuyModal = () => {
    show({
      title: t('modal.title.buy'), action: 'buy', config: buyModalParams, initialValues: buyInitialValues, submitButton: t('button.buy'), options: { lenders },
    });
  };

  const handleTransferModal = ({ ownershipId, lenderId }) => {
    show({
      title: t('modal.title.transfer'), action: 'transfer', config: transferModalParams, initialValues: { ...transferInitialValues, ownershipId, lenderId }, submitButton: t('button.transfer'),
    });
  };

  const handleRedeemInit = (data) => {
    const dateTime = formatDateTime(data.dateTime);
    const transactionData = { ...data, dateTime };
    show({
      title: t('modal.title.redeem'), action: 'redeem', config: historyInfoModalParams, initialValues: transactionData, submitButton: t('button.redeem'),
    });
  };

  const handleButtonClick = (action, data) => {
    switch (action) {
      case 'transfer':
        return handleTransferModal(data, 'create');
      case 'redeem':
        return handleRedeemInit(data);
      default:
        break;
    }
  };

  return (
    <Container>
      {modal}
      <ButtonCreateElement onClick={() => handleBuyModal(null, 'create')} t={t('button.buy')} />
      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        <SelaaTable
          title={t('titleTable.transaction')}
          data={transactions}
          params={transactionsParams}
          onButtonClick={handleButtonClick}
        />
      </Box>
      <Box xs={12} sx={{ display: { xs: 'block', sm: 'none' } }}>
        <SelaaCards
          data={transactions}
          params={transactionsParams}
          visibleFields={4}
          onButtonClick={handleButtonClick}
        />
      </Box>
    </Container>
  );
};

export default Transaction;
