import React from 'react';
import { useSearchParams } from 'react-router-dom';
import PDF from '@todcode/components/src/component/PDF';

const PDFPage = () => {
  // Hook
  const [searchParams] = useSearchParams();

  const url = searchParams.get('url');

  return (
    <PDF file={url} />
  );
};

export default PDFPage;
